/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'

import { LoadingButton } from '@mui/lab'
import { Box, Button, Grid, Modal, TextField, Typography } from '@mui/material'
import { fetchAuthSession } from 'aws-amplify/auth'
import { useSelector } from 'react-redux'

import { Customer } from '../../models/Customer'
import { SalesItem } from '../../models/SalesItem'
import { SalesOrder } from '../../models/SalesOrder'
import { Transaction } from '../../models/Transaction'
import { currencyFormatter } from '../../utils'

type Props = {
  isShown: boolean
  onClose: any
  order: SalesOrder
  customer: Customer
  type: string
}

const EmailConfirmationModal = ({ isShown, onClose, order, customer, type }: Props) => {
  const [open, setOpen] = useState<boolean>(isShown)
  const [loading, setLoading] = useState<boolean>(false)
  const [header, setHeader] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [state, setState] = useState<'init' | 'error' | 'success'>('init')
  const [email, setEmail] = useState<string>('')
  const fulfilledOrderItems = useSelector((state: any) => state.order.fulfilledOrderItems)
  const unfulfilledOrderItems = useSelector((state: any) => state.order.unfulfilledOrderItems)
  const trackingList = useSelector((state: any) => state.order.trackingList)

  useEffect(() => {
    setOpen(isShown)
    if (!isShown) {
      initState()
      setEmail(customer._email || '')
    }
  }, [isShown, loading, message])

  const getLineItems = () => {
    const tmpOrderItems: any[] = []
    fulfilledOrderItems.map((items: SalesItem[]) => {
      if (items.length > 0) {
        items.map((element: SalesItem) => {
          if (element.currentShopifyQuantity > 0) {
            tmpOrderItems.push({
              name: `${element.title.split(' |', 2)[0]} - ${element.variantTitle}`,
              quantity: element.initialQuantity,
              img: `${element.srcImage}.jpg`,
            })
          }
        })
      }
    })
    unfulfilledOrderItems.map((items: SalesItem[]) => {
      if (items.length > 0) {
        items.map((element: SalesItem) => {
          if (element.quantity > 0) {
            tmpOrderItems.push({
              name: `${element.title.split('|', 2)[0]} - ${element.variantTitle}`,
              quantity: element.initialQuantity,
              img: `${element.srcImage}.jpg`,
            })
          }
        })
      }
    })
    return tmpOrderItems
  }

  const initState = () => {
    setState('init')
    setHeader(`Resend ${type} confirmation`)
    setMessage(`Are you sure you want to resend ${type} notification?`)
  }
  const setUnknownErrorState = () => {
    setState('error')
    setHeader('Woops')
    setMessage(`Something went wrong, unable to resend ${type} confirmation`)
  }

  const formattedPrice = (price: string) => {
    return order._currencyCode !== 'USD'
      ? currencyFormatter(customer, order).format(parseFloat(price)) + ' ' + order._currencyCode
      : currencyFormatter(customer, order).format(parseFloat(price))
  }

  const createKlayviyoEvents = () => {
    setLoading(true)
    const transactions: any = []
    const tmpTrackingList: any = []
    order._transactions
      .filter((transaction: Transaction) => {
        return transaction.kind !== 'REFUND'
      })
      .forEach((transaction: Transaction) =>
        transactions.push({
          method: transaction.paymentMethod
            ? transaction.paymentMethod
            : transaction.formattedGateway,
          total: formattedPrice(transaction.amount.toString()),
        }),
      )
    const requestPayload: {
      [key: string]: any
    } = {}
    requestPayload['items'] = getLineItems()
    requestPayload['shipping_address'] = {
      address1: customer._shippingAddress.address1,
      city: customer._shippingAddress.city,
      zip: customer._shippingAddress.zip,
      province_code: customer._shippingAddress.provinceCode,
      country: customer._shippingAddress.country,
    }
    requestPayload['sub_total'] = formattedPrice(order._currentSubtotalPriceSet)
    requestPayload['tax'] = formattedPrice(order._currentTotalTaxSet)
    requestPayload['shipping_cost'] = formattedPrice(order._totalShippingPriceSet)
    requestPayload['total'] = formattedPrice(order._currentTotalPriceSet)
    requestPayload['order_status_url'] = order._statusPageUrl
    requestPayload['payment_methods'] = transactions
    requestPayload['billing_address'] = {
      address1: customer._billingAddress.address1,
      city: customer._billingAddress.city,
      zip: customer._billingAddress.zip,
      province_code: customer._billingAddress.provinceCode,
      country: customer._billingAddress.country,
    }
    requestPayload['email'] = email
    requestPayload['phone_number'] = customer._phone
    if (order._shippingMethod) {
      requestPayload['shipping_method'] = order._shippingMethod
    }
    if (trackingList) {
      trackingList.forEach((tracking: any) => {
        tmpTrackingList.push({ title: tracking.number })
      })
      requestPayload['tracking_numbers'] = tmpTrackingList
    }
    if (order._currentTotalDiscountsSet !== '0.0') {
      requestPayload['discount'] = formattedPrice(order._currentTotalDiscountsSet)
    }
    fetchAuthSession()
      .then((session) => {
        const accesToken = session.tokens?.accessToken?.toString()
        return fetch(
          `${process.env.REACT_APP_AIRBOSS_DOMAIN}/api/v2/order/klaviyo/${order._id}/email_confirmation/`,
          {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accesToken}`,
            },
            body: JSON.stringify(requestPayload),
          },
        )
      })
      .then((data) => {
        if (data.status === 202) {
          setState('success')
        } else {
          setUnknownErrorState()
        }
        setLoading(false)
      })
      .catch((err: any) => {
        console.log('error', err)
        setUnknownErrorState()
        setLoading(false)
      })
  }

  const emailConfirmationModalStyle = (tmpWidth: number) => {
    return {
      textAlign: 'center',
      position: 'absolute',
      top: '40%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: tmpWidth,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
    }
  }

  const CloseRow = () => {
    return (
      <React.Fragment>
        <Grid item paddingBottom={2}>
          <Typography variant='body1'>{message}</Typography>
        </Grid>
        <Grid item>
          <Button variant='contained' color='neutral' onClick={onClose}>
            Close
          </Button>
        </Grid>
      </React.Fragment>
    )
  }

  const SuccessRow = () => {
    return (
      <React.Fragment>
        <Grid>
          <Typography variant='body1'>Successfully resent {type} confirmation</Typography>
        </Grid>
        <Grid item>
          <Button variant='contained' color='neutral' onClick={onClose}>
            Close
          </Button>
        </Grid>
      </React.Fragment>
    )
  }

  return (
    <Modal
      id='email-confirmation-modal'
      open={open}
      aria-labelledby='email-confirmation-modal-title'
      aria-describedby='email-confirmation-modal-description'
      onClose={onClose}
    >
      <Box
        sx={emailConfirmationModalStyle(state === 'init' ? 600 : 400)}
        id='email-confirmation-modal-box'
      >
        <Grid container direction='column' justifyContent='center' alignItems='center' spacing={2}>
          <Grid item>
            <Typography variant='h2' fontSize={'1.5rem'}>
              {header}
            </Typography>
          </Grid>
          <Grid item width={'100%'}>
            <Grid container direction='column' justifyContent='center' alignItems='center'>
              {state === 'init' ? (
                <Grid
                  container
                  direction='row'
                  justifyContent='center'
                  alignItems='flex-start'
                  spacing={2}
                  sx={{
                    margin: '15px auto',
                    flexGrow: 1,
                  }}
                  padding={'auto'}
                >
                  <Grid container direction='row' justifyContent='flex-start'>
                    <TextField
                      data-lpignore='true'
                      autoComplete='off'
                      type='email'
                      variant='outlined'
                      color='secondary'
                      label='Email'
                      fullWidth
                      required
                      sx={{ mb: 4 }}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Grid container direction='row' justifyContent='center' alignItems='center'>
                      <Grid item xs={6} paddingLeft={0}>
                        <Button variant='contained' color='neutral' onClick={onClose}>
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item xs={6} paddingLeft={0}>
                        <LoadingButton
                          type='submit'
                          color='primary'
                          size='medium'
                          loading={loading}
                          variant='contained'
                          sx={{ mt: 3, mb: 2 }}
                          onClick={createKlayviyoEvents}
                        >
                          Confirm
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : state === 'success' ? (
                <SuccessRow />
              ) : (
                <CloseRow />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

export default EmailConfirmationModal
